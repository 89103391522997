import React, { useEffect, useState, useReducer } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";

// RSuite
import {
  Col,
  DateRangePicker,
  Grid,
  Row,
  Table,
  Button,
  Drawer,
  SelectPicker,
} from "rsuite";

// ReCharts
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Components
import Stats from "./components/stats";
import Filter from "./components/filter";
import PageTitle from "../../components/page_title";
import NoDataPieChart from "./components/noDataPieChart";
import GradeBarChart from "./charts/GradeBarChart";
import LocationBarChart from "./charts/LocationBarChart";

// Helper
import {
  COLORS,
  initialState,
  StatsObject,
  FormatArray,
  isToday,
  isYesterday,
  isLastWeek,
  formatChartDataByGrade,
  formatChartDataByCountries,
  handleGradeLegendData,
  handleCountryLegendData,
  getAllStates,
  getAllCountries,
} from "./helper";
import { fetcher } from "../../utils/fetcher";
import { ordinalize } from "../../utils/ordinalize";
import {
  format_end_of_day_in_time_zone,
  format_start_of_day_in_time_zone,
} from "../../utils/date_formatter";
import emptyImg from "../../../../images/empty-data.png";

const STATISTICS_URL = "api/v0/statistics/";

const reducer = (state, action) => {
  switch (action.type) {
    case "setGradeOptions":
      return { ...state, gradeOptions: action.payload };
    case "setCourseOptions":
      return { ...state, courseOptions: action.payload };
    case "setGradeIds":
      return { ...state, gradeIds: action.payload };
    case "setLocationId":
      return { ...state, locationId: action.payload };
    case "setAge":
      return { ...state, age: action.payload };
    case "setGender":
      return { ...state, gender: action.payload };
    case "setLocations":
      return { ...state, locations: action.payload };
    case "setProvinceIds":
      return { ...state, provinceIds: action.payload };
    case "setCourseIds":
      return { ...state, courseIds: action.payload };
    case "setCourseType":
      return { ...state, courseType: action.payload };
    case "setApplied":
      return { ...state, applied: action.payload };
    case "setAppliedData":
      return { ...state, appliedData: action.payload };
    case "setClearFilters":
      return {
        ...state,
        courseType: "",
        gradeIds: [],
        locationId: "",
        age: "",
        gender: "",
        provinceIds: [],
        otherTypeIds: [],
        courseIds: [],
        certificateGeneratedIds: [],
        appliedData: {},
      };
    default:
      return state;
  }
};

const Index = () => {
  const { Column, HeaderCell, Cell: TableCell } = Table;
  const { afterToday } = DateRangePicker;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [open, setOpen] = useState(false);
  const [dateSelected, setDateSelected] = useState([new Date(), new Date()]);
  const [formattedLatestEnrollments, setFormattedLatestEnrollments] = useState(
    [],
  );
  const [statsObj, setStatsObj] = useState([]);
  const [startDate, setStartDate] = useState(
    format_start_of_day_in_time_zone(new Date()),
  );
  const [endDate, setEndDate] = useState(
    format_end_of_day_in_time_zone(new Date()),
  );
  const [groupByChart, setGroupByChart] = useState("Course Grades");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [chartData, setChartData] = useState({
    name: "Course Grades",
    items: [],
    hasItems: false,
  });

  const { data: grades, isLoading } = useSWR("/api/v0/grades", fetcher);
  const { data: locations } = useSWR("/api/v0/locations", fetcher);
  const { data: topEnrollments } = useSWR(
    "/api/v0/courses/top?limit=5",
    fetcher,
  );
  const { data: latestEnrollments } = useSWR(
    "/api/v0/enrollments/latest?limit=5",
    fetcher,
  );

  const { data: statistics } = useSWR(
    {
      url: `${STATISTICS_URL}/summary`,
      params: {
        start_date: startDate,
        end_date: endDate,
        grade_id: state?.appliedData?.gradeIds,
        course_id: state?.appliedData?.courseIds,
        country: state?.appliedData?.locationId,
        country_state: state?.appliedData?.provinceIds,
        age: state?.appliedData?.age,
        gender: state?.appliedData?.gender,
      },
    },
    fetcher,
  );

  const { data: statisticsGroup } = useSWR(
    {
      url: `${STATISTICS_URL}/${groupByChart === "Countries" ? "country" : "grade"}`,
      params: {
        start_date: startDate,
        end_date: endDate,
        grade_id: state?.appliedData?.gradeIds,
        course_id: state?.appliedData?.courseIds,
        country: selectedCountry
          ? selectedCountry
          : state?.appliedData?.locationId,
        country_state: state?.appliedData?.provinceIds,
        age: state?.appliedData?.age,
        gender: state?.appliedData?.gender,
      },
    },
    fetcher,
  );

  const pieData = [
    {
      name: "1 or more Enrollments",
      students: statistics?.students - statistics?.inactive_students,
    },
    { name: "No Enrollment", students: statistics?.inactive_students },
  ];

  let gradeLegendData = {};
  let countryLegendData = {};
  if (groupByChart === "Countries") {
    countryLegendData = handleCountryLegendData(
      selectedCountry,
      statisticsGroup,
    );
  } else {
    gradeLegendData = handleGradeLegendData(
      statisticsGroup?.enrollments_by_grade,
      statisticsGroup?.certificates_by_grade,
      statisticsGroup?.content_requests_by_grade,
      statisticsGroup?.content_completions_by_grade,
      statisticsGroup?.total_content_completions_by_grade,
    );
  }

  useEffect(() => {
    if (!grades) return;
    const gradeOptions = grades?.data?.map((grade) => {
      let tooltipText = "Grade " + grade.attributes.name;
      if (
        ["primary", "secondary", "upper"].includes(
          grade.attributes.name.toLowerCase(),
        )
      ) {
        tooltipText = grade.attributes.name;
      }
      return {
        value: grade.id,
        label: ordinalize(grade.attributes.name),
        tooltip: tooltipText,
      };
    });
    dispatch({
      type: "setGradeOptions",
      payload: [...gradeOptions],
    });
    calculateChartData();
  }, [grades, selectedCountry, statistics, statisticsGroup]);

  useEffect(() => {
    if (!locations) return;
    dispatch({
      type: "setLocations",
      payload:
        locations?.countries && locations?.afg_states
          ? locations
          : {
              countries: {},
              afg_states: {},
            },
    });
    if (state.appliedData && state.appliedData.locationId) {
      setGroupByChart("Countries");
    }
  }, [locations, state.appliedData.locationId]);

  const calculateChartData = () => {
    if (groupByChart === "Countries") {
      let type = "country";
      let items = [];
      let registrationsList = {};
      let enrollmentsList = {};
      let certificatesList = {};
      let contentRequestedList = {};
      let contentCompletedList = {};
      let totalContentCompletedList = {};
      if (selectedCountry) {
        type = "province";
        items = getAllStates(locations);
        registrationsList =
          statisticsGroup?.registrations_by_country_state ?? {};
        enrollmentsList = statisticsGroup?.enrollments_by_country_state ?? {};
        certificatesList = statisticsGroup?.certificates_by_country_state ?? {};
        contentRequestedList =
          statisticsGroup?.content_requests_by_country_state ?? {};
        contentCompletedList =
          statisticsGroup?.content_completions_by_country_state ?? {};
        totalContentCompletedList =
          statisticsGroup?.total_content_completions_by_country_state ?? {};
      } else {
        items = getAllCountries(locations);
        registrationsList = statisticsGroup?.registrations_by_country ?? {};
        enrollmentsList = statisticsGroup?.enrollments_by_country ?? {};
        certificatesList = statisticsGroup?.certificates_by_country ?? {};
        contentRequestedList =
          statisticsGroup?.content_requests_by_country ?? {};
        contentCompletedList =
          statisticsGroup?.content_completions_by_country ?? {};
        totalContentCompletedList =
          statisticsGroup?.total_content_completions_by_country ?? {};
      }
      const chartDataByCountries = formatChartDataByCountries(
        type,
        items,
        registrationsList,
        enrollmentsList,
        certificatesList,
        contentRequestedList,
        contentCompletedList,
        totalContentCompletedList,
      );
      setChartData({
        ...chartData,
        name: selectedCountry ? "Provinces" : "Countries",
        items: chartDataByCountries.data?.sort((item1, item2) =>
          item1.registrations > item2.registrations ? -1 : 1,
        ),
        hasItems: chartDataByCountries.hasData,
      });
    } else {
      const chartDataByGrade = formatChartDataByGrade(
        grades,
        statisticsGroup?.enrollments_by_grade ?? {},
        statisticsGroup?.certificates_by_grade ?? {},
        statisticsGroup?.content_requests_by_grade ?? {},
        statisticsGroup?.content_completions_by_grade ?? {},
        statisticsGroup?.total_content_completions_by_grade ?? {},
      );
      setChartData({
        ...chartData,
        name: "Course Grades",
        items: chartDataByGrade.data,
        hasItems: chartDataByGrade.hasData,
      });
    }
  };

  const renderValue = (value) => {
    if (isToday(value) || isYesterday(value) || isLastWeek(value)) {
      setStartDate(
        format_start_of_day_in_time_zone(value[0].setHours(0, 0, 0, 0)),
      );
      setEndDate(format_end_of_day_in_time_zone(value[1]));
      if (!isLastWeek(value)) {
        return [
          isToday(value) ? "Today" : isYesterday(value) ? "Yesterday" : "",
        ];
      }
    }

    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return `${formatter.format(value[0])} to ${formatter.format(value[1])}`;
  };

  const handleOk = (value) => {
    setStartDate(format_start_of_day_in_time_zone(value[0]));
    setEndDate(format_end_of_day_in_time_zone(value[1]));
  };

  const handleClean = () => {
    setStartDate(format_start_of_day_in_time_zone(new Date()));
    setEndDate(format_end_of_day_in_time_zone(new Date()));
  };

  const handleFilters = (status) => {
    status && setOpen(false);
    if (status) {
      dispatch({
        type: "setAppliedData",
        payload: {
          courseType: state?.courseType,
          gradeIds: state?.gradeIds,
          courseIds: state?.courseIds,
          locationId: state?.locationId,
          provinceIds: state?.provinceIds,
          age: state?.age,
          gender: state?.gender,
        },
      });
      dispatch({
        type: "setApplied",
        payload: true,
      });
    }
  };

  useEffect(() => {
    if (latestEnrollments?.data?.length) {
      setFormattedLatestEnrollments(FormatArray(latestEnrollments?.data ?? []));
    }
  }, [latestEnrollments?.data]);

  useEffect(() => {
    setStatsObj(StatsObject(statistics));
  }, [statistics]);

  useEffect(() => {
    if (!open && !state.applied && !Object.keys(state.appliedData)?.length) {
      dispatch({
        type: "setApplied",
        payload: false,
      });
      dispatch({
        type: "setClearFilters",
      });
    }
    if (
      open &&
      !(
        state?.courseIds?.length ||
        state?.gradeIds?.length ||
        state?.locationId?.length ||
        state?.provinceIds?.length ||
        state?.age?.length ||
        state?.gender?.length
      )
    ) {
      dispatch({
        type: "setCourseType",
        payload: "",
      });
    }
    if (!open && Object.keys(state.appliedData)?.length) {
      dispatch({
        type: "setCourseType",
        payload: state?.appliedData?.courseType,
      });
      dispatch({
        type: "setGradeIds",
        payload: state?.appliedData?.gradeIds,
      });
      dispatch({
        type: "setProvinceIds",
        payload: state?.appliedData?.provinceIds,
      });
      dispatch({
        type: "setCourseIds",
        payload: state?.appliedData?.courseIds,
      });
      dispatch({
        type: "setLocationId",
        payload: state?.appliedData?.locationId,
      });
      dispatch({
        type: "setAge",
        payload: state?.appliedData?.age,
      });
      dispatch({
        type: "setGender",
        payload: state?.appliedData?.gender,
      });
      dispatch({
        type: "setApplied",
        payload: false,
      });
    }
  }, [open, state.applied]);

  return (
    <div className="container">
      <div className="mb-3">
        <div className="row align-items-center">
          <div className="col-md-4">
            <PageTitle title={"Dashboard"} />
          </div>
          <div className="col-md-8">
            <div className="d-flex justify-content-end">
              <DateRangePicker
                editable={false}
                shouldDisableDate={afterToday()}
                placement="bottomEnd"
                showOneCalendar
                className="date-filter"
                defaultValue={dateSelected}
                renderValue={renderValue}
                placeholder="Today"
                onOk={handleOk}
                onClean={handleClean}
                onSelect={(value) => setDateSelected(value)}
              />
              <Button
                className="ms-3"
                appearance="default"
                onClick={() => setOpen(true)}
              >
                <i className="fa-solid fa-sliders me-2"></i>
                All Filters
              </Button>
              <Drawer
                size={"xs"}
                open={open}
                onClose={() => setOpen(false)}
                className="custom-filters"
              >
                <Drawer.Header>
                  <Drawer.Title className="head-lg">Filters</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                  <Filter
                    {...{ state, dispatch }}
                    grades={state?.gradeOptions ?? []}
                    courses={state?.courseOptions ?? []}
                    handleFilters={handleFilters}
                  />
                </Drawer.Body>
              </Drawer>
            </div>
          </div>
        </div>
      </div>
      <Grid fluid={"100%"} className="p-0">
        <Row gutter={20}>
          <Stats
            {...{ state }}
            items={statsObj}
            startDate={startDate}
            endDate={endDate}
          />
        </Row>
      </Grid>

      <div className="p-0">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column">
            <div className="shadow-sm bg-white radius-5 p-4 pt-0 mb-md-4 mb-2 h-100 grade-chart">
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div>
                  {selectedCountry ? (
                    <Link
                      onClick={() => setSelectedCountry(null)}
                      className="f-semi"
                    >
                      <i className="fa fa-angle-left me-2"></i>
                      Back to countries
                    </Link>
                  ) : null}
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="me-2">Group By</div>
                  <div>
                    <SelectPicker
                      className="w-100"
                      block
                      value={groupByChart}
                      data={[
                        {
                          label: "Course Grades",
                          value: "Course Grades",
                        },
                        {
                          label: "Countries",
                          value: "Countries",
                        },
                      ]}
                      placement="autoVerticalStart"
                      menuMaxHeight={200}
                      style={{
                        width: 224,
                        minWidth: 180,
                      }}
                      placeholder="Select"
                      countable={false}
                      searchable={false}
                      onChange={(newValue) => setGroupByChart(newValue)}
                      onClean={() => {
                        setSelectedCountry(null);
                        setGroupByChart("Course Grades");
                      }}
                    />
                  </div>
                </div>
              </div>

              {groupByChart === "Countries" ? (
                <LocationBarChart
                  {...{ state }}
                  isProvince={selectedCountry ? true : false}
                  countriesChartData={chartData.items}
                  hasData={chartData.hasItems}
                  legendData={countryLegendData}
                  startDate={startDate}
                  endDate={endDate}
                  handleCountry={(value) => setSelectedCountry(value)}
                />
              ) : (
                <GradeBarChart
                  {...{ state }}
                  gradeChartData={chartData.items}
                  hasData={chartData.hasItems}
                  legendData={gradeLegendData}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}

              {/* hasGradeData || hasCountriesData ? (
                <ResponsiveContainer width="100%" height={280}>
                  {
                    groupByChart === "Countries" ? (
                      <BarChart
                        width={730}
                        margin={{
                          top: 5,
                          left: 0,
                          bottom: 5,
                          right: 0,
                        }}
                        data={countriesChartData ?? []}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="country"
                          label={{
                            value: "Countries",
                            position: "insideBottom",
                            offset: "-112",
                          }}
                          tick={{ fontSize: 12 }}
                          textAnchor="end"
                          angle={-25}
                          onClick={(country) => {
                            if (country.value.includes("Afghanistan")) setSelectedCountry(country.value);
                          }}
                        />
                        <YAxis
                          allowDecimals={false}
                          label={{
                            value: "Numbers",
                            angle: -90,
                            position: "left",
                            offset: "-3",
                          }}
                        />
                        <ReferenceLine y={0} stroke="#000" />
                        <Brush
                          dataKey={() => null} // remove text in brush component
                          y= {340}
                          height={15}
                          startIndex={0}
                          endIndex={15}
                          stroke="#198754"
                          padding={{ top: 55 }}
                          margin={{ top: 55 }}
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" height={34} iconType="square" />
                        <Bar
                          name={`Registrations (${enrollmentsCount}) `}
                          dataKey="registrations"
                          fill="#0d6d18"
                        />
                        <Bar
                          name={`Enrollments (${enrollmentsCount}) `}
                          dataKey="Enrollment"
                          fill="#0d6d18"
                        />
                        <Bar
                          name={`Certificates Issued (${certificatesCount}) `}
                          dataKey="certificates"
                          fill="#18181a"
                        />
                        <Bar
                          name={`Lessons Requested (${contentRequestedCount}) `}
                          dataKey="contentRequested"
                          fill="#43C4C7"
                        />
                        {new Date(startDate) > new Date("2024-05-07") && (
                          <Bar
                            name={`Requested Lessons Completed (${contentCompletedCount}) `}
                            dataKey="contentCompleted"
                            fill="#FF8042"
                          />
                        )}
                        <Bar
                          name={`Total Lessons Completed (${totalContentCompletedCount}) `}
                          dataKey="totalContentCompleted"
                          fill="#B442FF"
                        />
                      </BarChart>
                    ) : (
                      <BarChart data={gradeChartData ?? []}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          angle={-45}
                          textAnchor="end"
                          tick={{ fontSize: 12 }}
                          dataKey="grade"
                          label={{
                            value: "Course Grades",
                            position: "insideBottom",
                            offset: "-9",
                          }}
                        />
                        <YAxis
                          allowDecimals={false}
                          label={{
                            value: "Numbers",
                            angle: -90,
                            position: "left",
                            offset: "-3",
                          }}
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" height={34} iconType="square" />
                        <Bar
                          name={`Enrollments (${enrollmentsCount}) `}
                          dataKey="enrollments"
                          fill="#0d6d18"
                        />
                        <Bar
                          name={`Certificates Issued (${certificatesCount}) `}
                          dataKey="certificates"
                          fill="#18181a"
                        />
                        <Bar
                          name={`Lessons Requested (${contentRequestedCount}) `}
                          dataKey="contentRequested"
                          fill="#43C4C7"
                        />
                        {new Date(startDate) > new Date("2024-05-07") && (
                          <Bar
                            name={`Requested Lessons Completed (${contentCompletedCount}) `}
                            dataKey="contentCompleted"
                            fill="#FF8042"
                          />
                        )}
                        <Bar
                          name={`Total Lessons Completed (${totalContentCompletedCount}) `}
                          dataKey="totalContentCompleted"
                          fill="#B442FF"
                        />
                      </BarChart>
                    )
                  }
                </ResponsiveContainer>
              ) : (
                <NoDataFound />
              )*/}
            </div>
          </div>
          <div className="col-lg-5">
            <div className="shadow-sm bg-white radius-5 p-4 mb-md-4 mb-2">
              {statistics?.students ? (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={pieData}
                      isAnimationActive={true}
                      label={true}
                      nameKey="name"
                      dataKey="students"
                      labelLine
                      cx="50%"
                      cy="50%"
                      innerRadius={95}
                      outerRadius={150}
                      fill="#8884d8"
                    >
                      {pieData?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                      <Label
                        value="Total Registrations"
                        position="centerBottom"
                        className="label-top total-label top"
                        fontSize="12px"
                      />
                      <Label
                        value={statistics?.students}
                        position="centerTop"
                        className="label-top f-med total-label count"
                        fontSize="35px"
                      />
                    </Pie>
                    <Tooltip />
                    <Legend iconType="square" />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <NoDataPieChart />
              )}
            </div>
          </div>
        </div>
      </div>
      <Grid fluid={"100%"} className="p-0">
        <Row gutter={20}>
          <Col md={12} xs={24}>
            <div className="shadow-sm bg-white radius-5 mb-md-4 mb-2">
              <div className="d-flex justify-content-between align-items-center px-3 pt-2">
                <h2 className="head-md">Recent Enrollments</h2>
                {!!formattedLatestEnrollments?.length && (
                  <Link to={"/courses"} className="f-semi">
                    View All
                  </Link>
                )}
              </div>
              <Table
                loading={isLoading}
                height={300}
                data={formattedLatestEnrollments || []}
                locale={{
                  emptyMessage: (
                    <>
                      <img src={emptyImg} width={111} alt="Empty" />
                      <p className="head-md">Sorry, No data available!</p>
                    </>
                  ),
                }}
              >
                <Column flexGrow={1}>
                  <HeaderCell>Full Name</HeaderCell>
                  <TableCell dataKey="attributes.fullname" />
                </Column>
                <Column flexGrow={1}>
                  <HeaderCell>Age</HeaderCell>
                  <TableCell dataKey="attributes.age" />
                </Column>
                <Column flexGrow={1}>
                  <HeaderCell>Gender</HeaderCell>
                  <TableCell dataKey="attributes.gender" />
                </Column>
                <Column flexGrow={1}>
                  <HeaderCell>Country</HeaderCell>
                  <TableCell dataKey="attributes.country" />
                </Column>
                <Column flexGrow={1}>
                  <HeaderCell>Province</HeaderCell>
                  <TableCell dataKey="attributes.province" />
                </Column>
              </Table>
            </div>
          </Col>
          <Col md={12} xs={24}>
            <div className="shadow-sm bg-white radius-5 mb-md-4 mb-2">
              <div className="d-flex justify-content-between align-items-center px-3 pt-2">
                <h2 className="head-md">Top Enrolled Courses</h2>
                {!!topEnrollments?.length && (
                  <Link to={"/courses"} className="f-semi">
                    View All
                  </Link>
                )}
              </div>
              <Table
                loading={isLoading}
                height={300}
                data={topEnrollments || []}
                locale={{
                  emptyMessage: (
                    <>
                      <img src={emptyImg} width={111} alt="Empty" />
                      <p className="head-md">Sorry, No data available!</p>
                    </>
                  ),
                }}
              >
                <Column flexGrow={3}>
                  <HeaderCell>Course Name</HeaderCell>
                  <TableCell dataKey="title" />
                </Column>
                <Column flexGrow={2}>
                  <HeaderCell>Enrollments</HeaderCell>
                  <TableCell dataKey="enrollment_count" />
                </Column>
                <Column flexGrow={2}>
                  <HeaderCell>Language</HeaderCell>
                  <TableCell dataKey="language" />
                </Column>
                <Column flexGrow={3}>
                  <HeaderCell>Course Grade</HeaderCell>
                  <TableCell>
                    {(rowData) => {
                      return rowData?.["grades"]
                        ?.map(function (i) {
                          return ordinalize(i);
                        })
                        ?.join(", ");
                    }}
                  </TableCell>
                </Column>
              </Table>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Index;
